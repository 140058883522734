<template>
  <div class="headline content-container" :class="[{ 'headline--reverse': value.align && value.align === 'right' }]">
    <div data-aos="fade-in" data-aos-duration="700" data-aos-easing="cubic" class="headline__image" v-if="value.image">
      <Image :value="value.image" />
    </div>
    <div class="headline__content">
      <h2>
        <small v-if="value.caption" v-html="value.caption" />
        <span v-html="value.heading" />
      </h2>
      <div v-html="value.body" />
      <div v-if="value.button">
        <Btn highlight rounded v-html="value.button" :route="value.route" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
  }
}
</script>
<style lang="scss">
.headline {
  margin: auto;
  display: flex;

  >div {
    margin: 0 50px;
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  &__image {
    img {
      max-width: 500px;
      text-align: center;
      min-height: 300px;
      margin-top: 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    >h2 {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.4;
      margin: 20px 0 30px 0;
      position: relative;

      small {
        font-weight: 800;
      }

      >svg {
        fill: var(--color-background);
        position: absolute;
        z-index: -1;
        transform: scale(.8);
        top: -70px;
        left: -100px;
      }
    }

    >div {
      margin-bottom: 30px;
    }

    ul {
      list-style: none;
      margin-bottom: 0;

      li {
        margin-bottom: 20px;
        padding-left: 35px;
        position: relative;

        // font-size: 1.1rem;
        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: url('~@/assets/icons/check.svg');
          display: inline-block;
          transform: scale(2);
          position: absolute;
          top: 8px;
          left: 0;
          color: var(--color-accent)
        }
      }
    }
  }

  @media(max-width: 1024px) {
    flex-wrap: wrap;

    >div {
      margin: 0 auto;
    }

    .headline__image {
      margin-bottom: 40px;

      img {
        max-width: 100%;
        margin-top: 0;
        min-height: auto;
        max-height: 400px;
      }
    }
  }


}
</style>
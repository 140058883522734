<template>
  <div class="checklist content-container">
    <div v-for="(item, inc) in value" :key="item" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="500" :data-aos-delay="inc * 100" >
      <div>
        <Icon>mdi:check-circle-outline</Icon>
        <span v-html="item" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
  }
}
</script>
<style lang="scss">
  .checklist {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 70px 40px;
    > div {
      text-align: center;
      > div {
        text-align: left;
        display: block;
        max-width: 230px;
        margin: auto;
        display: flex;
        align-items: center;
        .icon {
          margin-right: 10px;
          color: var(--color-accent);
          font-size: 36px;
        }
        span {
          font-size: 1.4rem;
        }
      }
    }

    @media(max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

  }
</style>
<template>
  <div 
    class="splash"
  >
    <div class="splash__body content-container">
      <slot />
    </div>
    <div v-if="shadow" class="splash__shadow">
    </div>

    <div class="splash__video" v-if="video" :style="[{'opacity': opacity || 1}]" :key="video">
      <video ref="video" loop muted @load="onVideoLoad" autoplay :src="video" />
    </div>
    <div 
      v-else-if="image"
      class="splash__image"
      :style="[{'background-image': `url(${image})`}, {'opacity': opacity || 1}]"
    />
  </div>
</template>
<script>
export default {
  props: {
    hover: Boolean,
    image: String,
    video: String,
    shadow: Boolean,
    opacity: [String, Number],
    pattern: [String, Number],
  },
  data() {
    return {
      width: null,
      height: null,
      videoSrc: null,
      activeVideo: 1,
    }
  },
  computed: {
    particleCount() {
      return parseInt(( this.width * this.height) * .00003)
    }
  },
  mounted() {
    this.width = this.$el.offsetWidth
    this.height = this.$el.offsetHeight
  },
  methods: {
    onVideoLoad() {
      this.$nextTick(() => {
        this.$refs.video.play()
      })
    },
    patternStyles() {
      return {
        left: Math.floor(Math.random() * 100) + '%',
        top: Math.floor(Math.random() * 100) + '%',
        right: Math.floor(Math.random() * 100) + '%',
        bottom: Math.floor(Math.random() * 100) + '%',
        transform: `scale(${Math.random() * 3}) rotate(${Math.floor(Math.random() * 360)}deg)`,
        opacity: Math.floor(Math.random() * 100) + '%',
      }
    }
  }
}
</script>
<style lang="scss">
.splash {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: transform .5s ease;
  border-radius: var(--settings-border-radius);
  background: var(--color-primary);

  &__shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: .5;
    //background: linear-gradient(to bottom, rgba(#000,.55) 0%, rgba(#000,0) 50%, rgba(#000,.55) 100%);
    background: linear-gradient(to bottom, rgba(#000,.55) 0%, rgba(#000,0) 50%);
  }

  &__video {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    video {
      position: absolute;
      left: 50%; /* % of surrounding element */
      top: 50%;
      transform: translate(-50%, -50%); /* % of current element */
      height: 100%;
      width: 177.77777778vh; /* 100 * 16 / 9 */
      min-width: 100%;
      min-height: 56.25vw; /* 100 * 9 / 16 */
    }
    &__paginate {
      position: absolute;
      background: rgba(#000, .5);
      margin: auto;
      border-radius: 20px;
      bottom: 5px;
      left: calc(50% - 100px);
      padding: 5px 15px;
      color: #FFF;
      z-index: 9;
      text-align: center;
      span {
        color: rgba(#FFF, .5);
        font-weight: 400;
        cursor: pointer;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: #FFF;
        }
        &.active {
          font-weight: 900;
          color: #FFF;
        }
      }
    }
  }

  &__pattern {
    transition: transform .3s ease;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(90deg, #492532 0%, var(--color-primary) 100%);
    span {
      display: inline-block;
      background: rgba(var(--color-highlight-rgb), .7);
      box-shadow: 0px 0px 29px 1px rgba(var(--color-highlight-rgb), .7);
      border-radius: 50%;
      position: absolute;
      width: 50px;
      height: 50px;
    }
    &.pattern-1 span {
      border-radius: 50%;
    }
    &.pattern-2 span {
      border-radius: 0%;
    }
    &.pattern-3 span {
      width: 80%;
      height: 3px;
    }
    &.pattern-4 span {
      width: 0; 
      height: 0; 
      border-radius: 0;
      background: transparent;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid rgba(var(--color-highlight-rgb), .7);;
    }
  }

  &__body {
    z-index: 3;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &__image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center center;
    z-index: 1;
  }

}
</style>

<template>
  <Splash class="footer">
    <div class="footer__container">
      <!-- <div>
        <div class="footer__newsletter">
          <h4 v-html="data.newsletter.heading" />
          <p v-html="data.newsletter.message" />
          <div>
            <form @submit.prevent="onSubscribe">
              <input type="text" v-model="email" :placeholder="data.newsletter.placeholder" />
              <Btn small rounded v-html="data.newsletter.button"></Btn>
            </form>
          </div>
        </div>

      </div> -->
        <Logo light class="footer__logo" />
        <div class="footer__social">
          <a v-for="item in data.social" :key="item.icon" :href="item.url">
            <Icon :icon="item.icon" />
          </a>
        </div>
    </div>
    <div class="footer__contact">
      <div>
        <Icon>location</Icon>
        <span v-html="contact.address" />
      </div>
      <div>
        <Icon>bxs:phone</Icon>
        <span v-html="contact.phone" />
      </div>
      <div>
        <Icon>email</Icon>
        <a :href="`mailto:${contact.email}`" v-html="contact.email" />
      </div>
    </div>
    <ul class="footer__menu">
      <li v-for="item in data.menu" :key="item.label">
        <router-link v-if="item.route" :to="item.route" v-html="item.label" />
        <a href="javascript:;" v-else :onclick="item.click" v-html="item.label" />
      </li>
    </ul>
    <div class="footer__copyright">
      <span v-html="data.copyright" />
    </div>
  </Splash>
</template>
<script>
import FooterData from '@/content/global/Footer.json'
import ContactData from '@/content/global/Contact.json'
export default {
  computed: {
    data() {
      return FooterData
    }
  },
  data() {
    return {
      email: null,
      contact: ContactData,
    }
  },
  methods: {
    onSubscribe() {
      console.log('subscribe', this.email)
    }
  }
}
</script>
<style lang="scss">
  .footer {
    background: var(--color-primary);
    font-size: .85rem;
    color: var(--color-body);
    //padding: 50px 0 30px;
    a {
      color: inherit;
      &:hover {
        color: #FFF !important;
      }
    }
    &__logo {
      max-width: 135px;
      margin-right: 40px;
    }
    &__contact {
      width: 100%;
      margin-top: 10px;
      //background: rgba(#000,.2);
      border-top: 1px solid rgba(#FFF, .1);
      display: flex;
      font-size: .9rem;
      padding: 30px 0;
      justify-content: center;
      color: rgba(#FFF, .6);
      flex-wrap: wrap;
      .icon {
        margin-right: 5px;
      }
      a {
        text-decoration: underline;
      }
      > div {
        border-right: 1px solid rgba(#FFF, .1);
        padding-right: 20px;
        margin-right: 20px;
        display: inline-flex;
        align-items: center;
        &:last-child {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
    &__container {
      margin: 40px 0 20px 0;
      width: 100%;
      text-align: center;
      > div {
        margin: auto;
        display: inline-flex;
        align-items: center;
        text-align: center;
      }
      // > div:first-child {
      //   flex: 1;
      // }
      // > div:last-child {
      //   text-align: right;
      // }

    }
    &__social {
      opacity: .7;
      &:hover {
        opacity: 1;
      }
      margin-bottom: 0px;
      .icon {
        font-size: 33px;
        margin-right: 5px;
      }
      a:hover {
        color: var(--color-accent) !important;
      }
    }
    &__newsletter {
      margin-bottom: 0px;
      h4 {
        color: var(--color-body);
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 10px;
      }
      > p {
        opacity: .7;
        margin-bottom: 20px;
      }
      > div {
        display: flex;
        form {
          display: flex;
        }
        input {
          background: var(--color-body);
          border: none;
          border-radius: 40px;
          padding: 5px 20px;
          margin-right: 10px;
          font-size: .95rem;
        }
      }
    }
    &__menu {
      list-style: none;
      margin: 0;
      display: flex;
      padding: 0 0 30px 0;
      align-items: center;
      a {
        color: rgba(#FFF, .4);
      }
      > li {
        border-right: 1px solid rgba(#FFF, .1);
        padding-right: 10px;
        margin-right: 10px;
        font-size: .85rem;
        &:last-child {
          padding-right: 0;
          margin-right: 0;
          border: none;
        }
      }

    }
    &__copyright {
      opacity: .4;
      margin-bottom: 40px;
    }

    @media(max-width: 1024px) {
      .footer__container {
        flex-wrap: wrap;
        display: block;
        text-align: center;
        > div {
          margin: auto;
          text-align: center;
        }
      }
      .footer__menu {
        flex-wrap: wrap;
      }
      .footer__contact {
        > div:first-child {
          display: flex;
          flex-basis: 100%;
          justify-content: center;
          border: none;
          margin: 0 0 5px 0;
          padding: 0;
        }
      }
      .footer__newsletter {
        width: 100%;
        input {
          max-width: 180px;
        }
        > div {
          display: inline-block;
        }
      }
    }


  }
</style>
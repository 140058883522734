<template>
  <div class="text content-container">
    <div v-html="value" />
  </div>
</template>
<script>
export default {
  props: {
    value: String,
  }
}
</script>
<style lang="scss">
  .text {
    margin: -40px auto;
    text-align: center;
    > div {
      font-size: 1.3rem;
      font-weight: 400;
      max-width: 90%;
      margin: auto;
      // border: 1px solid var(--color-border);
      // padding: 40px;
      // border-radius: 40px;
      // &:before, &:after {
      //   content: '';
      //   max-width: 200px;
      //   margin: auto;
      //   display: block;
      //   margin: 30px auto;
      //   border-top: 1px dashed var(--color-highlight); 
      // }
    }
  }
</style>
<template>
  <header class="header" :class="[{'header--fixed': fixed || mobileMenu, 'header--mobile-open': mobileMenu}]">
    <div class="header__container content-container">
      <div>
        <router-link to="/"><Logo :dark="fixed || mobileMenu" /></router-link>
      </div>
      <div class="header__menu" :key="$route.name">
        <ul>
          <li v-for="item in content.menu" :key="item.label">
            <span>
              <router-link v-if="item.route" :to="item.route" v-html="item.label" />
              <a v-else href="javascript:;" v-html="item.label" />
              <Icon v-if="item.children">mdi:chevron-down</Icon>
            </span>
            <ul v-if="item.children">
              <li v-for="child in item.children" :key="child.label">
                <router-link :to="child.route">
                  <span v-html="child.label" />
                  <Icon>chevron-right</Icon>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="header__actions">
        <Btn 
          v-for="item in content.buttons" 
          :key="item.label"
          :route="item.route"
          v-html="item.label"
          small
          rounded
        />
      </div>
      <div class="header__mobile-toggle" @click="mobileMenu=!mobileMenu">
        <Icon v-if="!mobileMenu">dashicons:menu-alt3</Icon>
        <Icon v-else>fontisto:close-a</Icon>
      </div>
    </div>
    
  </header>
</template>
<script>
import Content from '@/content/global/Header.json'
import Logo from './Logo.vue'
export default {
  components: {
    Logo
  },
  data() {
    return {
      content: Content,
      fixed: false,
      mobile: false,
      mobileMenu: false,
    }
  },
  watch: {
    '$route'() {
      this.mobileMenu = false
    }
  },
  mounted() {
    this.setMobile()
    window.addEventListener('resize', this.setMobile)
    document.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount() {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.setMobile)
  },
  methods: {
    setMobile() {
      this.mobile = window.innerWidth > 1023 ? false : true
    },
    onScroll() {
      this.fixed = window.scrollY > 30
    }
  }
}
</script>
<style lang="scss">
  .header {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    text-align: center;
    position: fixed;
    color: #FFF;
    height: 120px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    // background: rgba(#000, .35);
    &--mobile-open {
      .header__menu {
        display: block !important;
      }
    }
    &--fixed {
      background: rgba(#FFF, 1);
      position: fixed;
      height: 80px;
      margin-top: 0;
      color: var(--color-text);
      box-shadow: 0px 0px 25px -10px rgba(#000, 0.25);
      .logo {
        transform: scale(.8);
      }
      .header__mobile-toggle .icon {
        color: var(--color-primary);
      }
      a {
        &.router-link-exact-active {
          color: var(--color-accent) !important;
        }
        &:hover {
          color: var(--color-highlight) !important;
        }
      }
    }
    &__container {
      text-align: left;
      display: flex;
      align-items: center;
      > div:first-child {
        flex: 1;
        .logo {
          transition: all .8s ease;
          max-width: 190px;
          &:hover {

          }
        }
      }
      > div:last-child {
        margin-left: 23px;
        width: 180px;
        text-align: right;
        button {
          transition: all .3s ease;
        }
      }
    }
    &__menu {
      margin-right: 20px;
      > ul {
        list-style: none;
        display: flex;
        margin: 0;
        > li {
          margin: 0 5px;
          font-weight: 600;
          font-size: 1.05rem;
          position: relative;
          > span {
            display: inline-flex;
            align-items: center;
            > a {
              color: inherit;
              padding: 6px 25px;
              &.router-link-exact-active {
                color: var(--color-brightlight);
                background: rgba(#FFF, .1);
                border-radius: 40px;
              }
              &:hover {
                color: var(--color-brightlight);
              }
            }
            .icon {
              width: 16px;
              position: relative;
              left: -20px;
            }
          }

          &:hover {
            > ul {
              opacity: 1;
              pointer-events: auto;
              transform: translateY(0px);
            }
          }
          > ul {
            background: var(--color-body);
            box-shadow: 0px 10px 15px 0px rgba(#000, 0.1);
            margin: 0;
            opacity: 0;
            transform: translateY(-10px);
            transition: all .2s ease;
            position: absolute;
            top: 55px;
            pointer-events: none;
            list-style: none;
            width: 240px;
            left: -10px;
            padding: 20px 10px;
            font-weight: 400;
            font-size: 1rem;
            border-radius: 5px;
            &:before {
              background: transparent;
              position: absolute;
              height: 20px;
              content: '';
              top: -20px;
              left: 0;
              width: 100%;
              display: block;
            }
            > li {

              .icon {
                opacity: 0;
              }
              a {
                display: flex;
                align-items: center;
                padding: 10px 10px;
                color: var(--color-text);
                &:hover {
                  color: var(--color-highlight) !important;
                  .icon {
                    opacity: 1;
                    transform: translateX(0);
                  }
                }
                > span {
                  flex: 1;
                }
                > .icon {
                  transform: translateX(-10px);
                  transition: all .3s ease;
                  opacity: 0;
                  font-size: 24px;
                  margin-right: -3px;
                }
              }
            }
          }
        }
      }
    }
    &__mobile-toggle {
      display: none;
      align-items: center;
      justify-content: flex-end;
      .icon {
        font-size: 24px;
        //color: var(--color-primary);
      }
    }
    @media(max-width: 1024px) {
      height: 100px;
      //background: var(--color-primary);

      .logo {
        max-width: 160px !important;
      }
      .header__mobile-toggle {
        display: flex;
      }
      .header__actions {
        display: none;
      }
      &.header--fixed {
        height: 70px !important
      }
      .header__menu {
        display: none;
        position: fixed;
        top: 70px;
        padding-top: 40px;
        left: 0;
        height: calc(100vh - 70px);
        width: 100%;
        background: #FFF;
        > ul {
          display: block;
          a {
            display: block;
          }
          > li {
            margin: 0 5px;
            padding: 20px 0;
            font-size: 2rem;
            &:first-child {
              > span {
                display: inline-flex;
              }
            }
            > ul {
              transform: none;
              opacity: 1;
              position: relative;
              width: auto;
              box-shadow: none;
              top: auto;
              font-size: 1.5rem;
              margin-left: 20px;
              > li {
                padding: 0px 0;
              }
            }
            > span {
              display: block;
              align-items: center;
              .icon {
                width: 30px;
                left: 0px;
              }
            }

          }
        }
      }
    }

  }
</style>
<template>
  <img class="logo" :src="svgLogo" />
</template>
<script>
import Logo from '@/assets/logo.json'
import svgToURI from 'mini-svg-data-uri'
export default {
  computed: {
    svgLogo() {
      if (!Logo) return
      const sizeLogo = this.condense ? Logo.condense : Logo.standard
      const colorLogo = this.dark ? sizeLogo.dark : sizeLogo.light
      return svgToURI(colorLogo)
    },
  },
  props: {
    dark: Boolean,
    condense: Boolean,
  },
}
</script>
<style lang="scss">
  .logo {
    width: auto;
    max-width: 100%;
  }
</style>
<template>
  <div class="app-features content-container">
    <div class="app-features__item" v-for="item in value" :key="item.id">
      <div>
        <h3 class="app-features__item__title" v-if="item.heading">
          <small v-if="item.caption" v-html="item.caption" />
          <span v-html="item.heading" />
        </h3>
        <div class="app-features__item__body" v-html="item.body" />
        <Btn rounded outline :route="item.route">{{ item.button }}</Btn>
      </div>
      <div>
      <div data-aos="fade-in" data-aos-easing="cubic" data-aos-duration="1000" class="app-features__item__image">
          <Image :value="item.image" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
  },
  methods: {

  }
}
</script>
<style lang="scss">
  .app-features {
    &__item {
      display: flex;
      margin-bottom: 120px;
      justify-content: center;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }
      > div {
        line-height: 1.8;
        margin: 0 50px;
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
      }
      &__image {
        text-align: center;
        > img {
          max-width: 100%;
          //max-height: 400px;
        }
      }
      &__body {
        flex: 1;
        margin-bottom: 30px;
        max-width: 500px;
      }
      &__title {
        font-size: 2.0rem;
        font-weight: 800;
        line-height: 1.4;
        margin-bottom: 15px;
        small {
          font-weight: 800;
        }
      }
    }

    @media(max-width: 1024px) {
      .app-features__item {
        flex-wrap: wrap;
        img {
          max-height: 400px;
        }
        > div {
          margin: 0 auto;
          &:first-child {
            margin-bottom: 60px;
          }
        }
      }
    }

  }
</style>
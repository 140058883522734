import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// global components
import Button from '@/components/app/Button.vue'
import Splash from '@/components/app/Splash.vue'
import Icon from '@/components/app/Icon.vue'
import Logo from '@/components/app/Logo.vue'
import Section from '@/components/content/Section'
import Image from '@/components/content/Image'

const app = createApp(App).use(store).use(router)

app.component('Btn', Button)
app.component('Splash', Splash)
app.component('Icon', Icon)
app.component('Logo', Logo)
app.component('Section', Section)
app.component('Image', Image)

app.mount('#app')


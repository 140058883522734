<template>
  <img v-if="value && src && !loading" :key="value" :src="src" />
</template>
<script>
export default {
  props: {
    value: null
  },
  mounted() {
    this.loadImage()
  },
  data() {
    return {
      src: null,
      loading: false,
    }
  },
  watch: {
    value() {
      this.loadImage()
    }
  },
  methods: {
    async loadImage() {
      this.loading = true
      const image = await import(`@/assets${this.value}`)
      this.src = image.default
      this.loading = false
    }
  }
}
</script>
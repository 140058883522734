export default {
  meta: {
    title: "Acádio | Contact Us",
    keywords: "Acádio, learning management system, lms, online training",
    description: ""
  },
  sections: [
    {
      id: "pricing-wip",
      component: "Text",
      heading: "Support",
      style: "",
      value: `
       <p><small>Need help?
		Head to the <a href="https://acadiolms.groovehq.com/help/" class="-bold" target="_blank">Knowledge Base</a> for feature walk-throughs, help docs, FAQs, and more.</p>
		<p>Monitor our uptime at <a href="https://acadio.statuspage.io/" target="_blank">acadio.statuspage.io</a>.</p>
		 <p>Send us a message and our support team will be in touch shortly!
        <br />We're available Monday thru Friday 9am-5pm (EST). All requests will be responded to within 1 business day. </p>
        <p>If you need immediate assistance, please call us at <a href="tel:1-407-904-9120">407-904-9120</a>.</small></p>
        
      `
	  },
	  {
      id: "form",
      component: "Form",
	    heading: "",
		  style: "margin-bottom: 60px; margin-top: -80px; max-width: 800px",
      value: {
        formId: "13cec24c-b8ad-47c2-8771-b5447b11af6e",
        image: ""
      }
    }
  ]
}

import cssVarsPolyFill from 'css-vars-ponyfill'
export default {

  data() {
    return {
      cssVars: {},
      theme: {
        base: {
          text: "#000000",
          body: "#FFFFFF",
          background: "#f2f3f6",
          focus: "#e8edf6",
          border: "#d8dadf",
          muted: "#999999"
        },
        color: {
          body: '#FFFFFF',
          text: '#191919',
          primary: '#0f2644',
          accent: '#f32b29',
          background: '#f5f5f5',
          highlight: '#03c',
          brightlight: '#dfdcff',
          border: '#ebebeb',
        },
        settings: {
          'page-width': '1400px',
        }
      }
    }
  },

  mounted() {
    if (this.theme) {
      this.setCSSVars()
    }
  },

  watch: {
    theme() {
      this.setCSSVars()
    }
  },

  methods: {
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null
    },
    addCSSVariable(key, value) {
      document.documentElement.style.setProperty(key, value)
      this.cssVars[key] = value
      if (value && value[0] !== '#') return
      let rgb = this.hexToRgb(value)
      if (!rgb) return
      let rgbValue = `${rgb.r}, ${rgb.g}, ${rgb.b}`
      const rgbKey = `${key}-rgb`
      document.documentElement.style.setProperty(`${key}-rgb`, rgbValue)
      this.cssVars[rgbKey] = rgbValue
    },
    setCSSVars() {
      const theme = this.theme
      // SET CSS VARS
      this.cssVars = {}
      for (let group in theme) {
        if (typeof theme[group] === 'object') {
          for(let prop in theme[group]) {
            this.addCSSVariable(`--${group}-${prop}`, theme[group][prop])
          }
        } else {
          this.addCSSVariable(`--${group}`, theme[group])
        }
      }
      // ponyfill for older browsers
      cssVarsPolyFill({
        variables: this.cssVars
      })
    }
  }
}
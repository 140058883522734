<template>
  <div class="highlights content-container">
    <div class="highlights__item" data-aos="fade-in" data-aos-easing="ease-out-cubic" data-aos-duration="600" :data-aos-delay="inc * 100" v-for="(item, inc) in value" :key="item.heading">
      <div class="highlights__item__icon"><Icon :icon="item.icon" /></div>
      <h4 class="highlights__item__title" v-html="item.heading" />
      <div class="highlights__item__body" v-html="item.body" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
  }
}
</script>
<style lang="scss">
  .highlights {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    margin: auto;
    &__item {
      > div {
        line-height: 1.8;
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
      }
      &__body {
        max-width: 340px;
        margin: auto;
      }
      &__icon {
        font-size: 5rem;
        line-height: 0 !important;
        text-align: center;
        margin-bottom: 20px;
        //color: var(--color-accent);
        img {
          max-width: 80px;
        }
      }
      &__title {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
      }
    }

    @media(max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media(max-width: 650px) {
      grid-template-columns: 1fr;
    }

  }
</style>
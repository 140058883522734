<template>
  <Image v-if="custom" :value="custom" />
  <i 
    v-else
    class="icon iconify-inline" 
    @click="$emit('click', $event)" 
    :class="[{'ux-icon--not-found': notFound}]" 
    :data-tooltip="tooltip" 
    :key="iconString"
    :style="{ fontSize: size }"
  >
    <span class="iconify-inline" :data-icon="iconData"  />
  </i>
</template>
<script>

import Iconify from '@iconify/iconify';
export default {
  props: {
    pack: {
      type: String,
      default: 'mdi'
    },
    size: String,
    title: String,
    tooltip: String,
    icon: String,
  },
  data() {
    return {
      notFound: false
    }
  },
  computed: {
    custom() {
      const str = this.icon || String(this.$slots.default()[0].children)
      return str && str[0] === '/' ? str : null
    },
    iconData() {
      return this.notFound ? 'ic:twotone-error' : this.iconString
    },
    iconString() {
      const str = this.icon || String(this.$slots.default()[0].children)
      return str.includes(':') ? str : `${this.pack}:${str}`
    },
  },
  mounted() {
    setTimeout(() => {
      this.notFound = !this.iconFound(this.iconString)
    }, 1500)
  },
  methods: {
    iconFound() {
      return !!Iconify.getIcon(this.iconString)
    },
  }
}
</script>
<style lang="scss">

  .icon {
    line-height: 0;
    position: relative;
    display: inline-flex;
    align-items: center;
    &--not-found {
      color: var(--color-error) !important;
    }
    &[data-tooltip] {
      &:hover {
        &:before, &:after {
          opacity: 1;
          transform: translateX(0);
        }
      }
      &:before {
        opacity: 0;
        transition: all .2s ease;
        transform: translateX(10px);
        pointer-events: none;
        content: attr(data-tooltip);
        background: rgba(#000, .65);
        color: #FFF;
        padding: 12px 12px;
        display: block;
        white-space: nowrap;
        border-radius: 30px;
        position: absolute;
        z-index: 1;
        bottom: -37px;
        left: -10px;
        font-style: normal;
        font-size: .85rem;
      }
      &:after {
        content: '';
        opacity: 0;
        transition: all .2s ease;
        transform: translateX(10px);
        position: absolute;
        z-index: 1;
        bottom: -13px;
        left: calc(50% - 6px);
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid rgba(#000, .65);
      }
    }

  }
</style>
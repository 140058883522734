<template>
  <div class="html-content content-container">
    <div v-html="value" />
  </div>
</template>
<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {

    }
  },
  async mounted() {

  }
}
</script>
<style lang="scss">
  .html-content {
    a {
      text-decoration: underline;
    }
  }
</style>
<template>
  <div class="app-columns content-container">
    <div class="app-columns__item" v-for="item in value" :key="item.id">
      <div v-if="item.icon" class="app-columns__item__icon">
        <Icon>{{ item.icon }}</Icon>
      </div>
      <div v-else-if="item.image" data-aos="fade-in" data-aos-easing="cubic" data-aos-duration="1000" class="app-columns__item__image">
        <Image :value="item.image" />
      </div>
      <div>
        <h3 class="app-columns__item__title" v-if="item.heading">
          <small v-if="item.caption" v-html="item.caption" />
          <span v-html="item.heading" />
        </h3>
        <div class="app-columns__item__body" v-html="item.body" />
        <Btn rounded outline :route="item.route">{{ item.button }}</Btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
  },
  methods: {

  }
}
</script>
<style lang="scss">
  .app-columns {
    display: flex;
    &__item {
      flex-basis: 50%;
      border-right: 1px solid var(--color-border);
      padding: 25px;
      margin: 0 50px;
      > div {
        line-height: 1.8;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
        padding-right: 0;
      }
      &__icon {
        font-size: 80px;
        margin-bottom: 25px;
        line-height: 0 !important;
        color: var(--color-primary);
      }
      &__image {
        text-align: center;
        margin-bottom: 40px;
        > img {
          max-width: 100%;
          //max-height: 400px;
        }
      }
      &__body {
        flex: 1;
        margin-bottom: 30px;
        max-width: 500px;
      }
      &__title {
        font-size: 2.1rem;
        font-weight: 900;
        line-height: 1.4;
        margin-bottom: 20px;
        small {
          font-weight: 900;
        }
      }
    }

    @media(max-width: 1024px) {
      display: block;
      .app-columns__item {
        padding: 20px;
        border: none;
        padding: 0;
        margin-bottom: 80px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }
</style>
<template>
  <section class="section" :style="value.style" :key="value.id">
    <a style="top: -150px; position: relative;" :name="value.id" />
    <div class="section__title" v-if="value.heading">
      <h2>
        <small v-if="value.caption" v-html="value.caption" />
        <span v-html="value.heading" />
      </h2>
    </div>
    <div v-if="value.html" class="-mb30 -align-center" v-html="value.html" />
    <component v-if="component" :is="component" :value="componentData"></component>
  </section>
</template>
<script>
import Checklist from '@/components/content/Checklist.vue'
import Features from '@/components/content/Features.vue'
import Highlights from '@/components/content/Highlights.vue'
import Banner from '@/components/content/Banner.vue'
import Headline from '@/components/content/Headline.vue'
import Compare from '@/components/content/Compare.vue'
import Divider from'@/components/content/Divider.vue'
import Text from '@/components/content/Text.vue'
import Html from '@/components/content/Html.vue'
import Statistic from '@/components/content/Statistic.vue'
import Articles from '@/components/content/Articles.vue'
import Form from '@/components/content/Form.vue'
import FAQs from '@/components/content/FAQs.vue'
import Tiles from '@/components/content/Tiles.vue'
import Columns from  '@/components/content/Columns.vue'
import Contact from '@/components/content/Contact.vue'
import Badges from '@/components/content/Badges.vue'
import Navigation from '@/components/content/Navigation.vue'
export default {
  components: {
    Checklist,
    Features,
    Highlights,
    Banner,
    Headline,
    Compare,
    Divider,
    Text,
    Statistic,
    Articles,
    Html,
    Form,
    FAQs,
    Tiles,
    Columns,
    Contact,
    Badges,
    Navigation,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      componentName: null,
      componentData: null,
      
    }
  },
  computed: {
    component() {
      if (!this.componentName) return
      return this.$options.components[this.componentName]
    }
  },
  mounted() {
    this.setComponent()
  },
  watch: {
    value() {
     // this.setComponent()
    }
  },
  methods:  {
    async setComponent() {
      if (this.value.import) {
        const importData = await import(`@/content/shared/${this.value.import}`)
        this.componentName = importData.component
        this.componentData = importData.value
      } else {
        this.componentName = this.value.component
        this.componentData = this.value.value
      }
    },
  }
}
</script>
<style lang="scss">
  .section {
    margin: 150px auto;
    //padding: 50px 0;
    &:first-child {
      margin-top: 100px;
    }
    &__title {
      text-align: center;
      margin-bottom: 80px;
      padding: 0 20px;
      > h2 {
        color: var(--color-primary);
        font-size: 2.5rem;
        font-weight: 800;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: relative;
        
        &:after {
          content: '';
          width: 50px;
          height: 1px;
          display: block;
          background: var(--color-accent);
          margin-left: 20px;
        }
        &:before {
          content: '';
          width: 50px;
          height: 1px;
          display: inline-block;
          background: var(--color-accent);
          margin-right: 20px;
        }
      }
    }

    @media(max-width: 1024px) {
      &__title > h2 {
        &:before, &:after {
          width: 20px;
          margin: 0 10px;
        }
      }
    }

  }
</style>
export default {
  meta: {
    title: "Acadio Documentation",
    keywords: "acadio apps, documentation, integrations",
    description: ""
  },
  sections: [
    {
      id: "introduction",
      component: "Text",
      heading: 'Documentation has Moved',
      value: `The platform documentation you are looking for has been moved to our online <a href="https://acadiolms.groovehq.com/help/google-oauth-2-dot-0-documenation" target="_blank" class="-bold">knowledge base</a>.`
    },
  ]
}

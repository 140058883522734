<template>
  <div class="articles content-container">
    <div class="articles__details" v-if="details">
      <div>
        <div>
          <Image :value="details.image" />
        </div>
        <div>
          <h1 class="articles__details__title" v-html="details.title" />
          <div class="articles__details__author" v-if="details.author"> By  {{ details.author }}</div>
          <div class="articles__details__body" v-html="details.body" />
        </div>
      </div>
      <h3>More Resources</h3>
    </div>
    <div class="articles__list">
      <div 
        v-for="item in articles"
        class="articles__list__item"
        :key="item.id"
      >
        <div class="articles__list__item__image">
          <Image :value="item.image" />
        </div>
        <div class="articles__list__item__content">
          <strong v-if="item.pretitle" v-html="item.pretitle" />
          <h3><router-link v-html="item.title" :to="getArticleRoute(item)" /></h3>
          <div>
            <p v-html="item.description" />
          </div>
          <div>
            <Btn :route="getArticleRoute(item)" small rounded outline>View Article</Btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
  },
  computed: {
    articles() {
      return this.$route.params.id ? this.value.filter(i => i.id !== this.$route.params.id) : this.value
    },
    details() {
      if (!this.$route.params.id) return
      return this.value.find(i => i.id === this.$route.params.id)
    },
    articleListRoute() {
      return { 

      }
    }
  },
  watch: {
    '$route'() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  methods: {
    getArticleRoute(article) {
      return {
        name: this.$router.name,
        params: {
          id: article.id
        }
      }
    }
  }
}
</script>
<style lang="scss">
  .articles {
    &__details {
      margin-bottom: 60px;
      &__title {
        font-size: 2.5rem;
        font-weight: 900;
        
      }
      &__author {
        margin-bottom: 10px;
        font-size: 1rem;
        color: rgba(var(--color-text-rgb), .6);
      }
      &__body {
        margin-top: 40px;
        font-size: 1.05rem;
      }
      > h3 {
        text-transform: uppercase;
        text-align: center;
        display: flex;
        margin: 100px 0;
        justify-content: center;
        align-items: center;
        &:after, &:before {
          content: '';
          display: inline-block;
          background: var(--color-highlight);
          height: 1px;
          margin: 0 10px;
          width: 100px; 
        }
      }
      > div {
        display: flex;
        img {
          width: auto;
          max-width: 100%;
        }
        > div:first-child {
          flex-basis: 500px;
          margin-right: 60px;
        }
        > div:last-child {
          flex: 1;
        }
      }
    }
    &__list {
      &__item {
        border-bottom: 1px solid var(--color-border);
        padding: 0 20px 60px 20px;
        margin: 0 0 60px 0;
        display: flex;
        &__image {
          flex-basis: 30%;
          margin-right: 80px;
          img {
            max-width: 375px;
            width: 100%;
          }
        }
        &__content {
          display: flex;
          flex: 1;
          flex-direction: column;
          padding-top: 20px;
          > strong {
            text-transform: uppercase;
            color: rgba(var(--color-text-rgb), .6);
            font-weight: 900;
            font-size: .9rem;
            margin-bottom: 5px;
          }
          > h3 > a {
            font-weight: 900;
            font-size: 1.7rem;
            margin-bottom: 15px;
            color: var(--color-primary);
            &:hover {
              color: var(--color-accent);
            }
          }
          > div:first-of-type {
            flex: 1;
          }
          > div:last-child {
            padding-bottom: 30px;
          }
          > p {
            font-size: 1rem;
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }

    @media(max-width: 1024px) {
      .articles__details {
        > div {
          display: block;
          img {
            margin-bottom: 40px;
          }
        }
      }
      .articles__list__item {
        display: block;
        &__image {
          margin: auto;
          margin-bottom: 40px;
          img {
            max-width: 500px;
          }
        }
        &__content {
          //margin: auto;
          max-width: 500px;
        }
      }
    }

  }
</style>
<template>
  <div class="divider content-container">
    <hr />
  </div>
</template>
<style lang="scss">
  .divider {
    margin: -50px auto;
    hr {
      background: var(--color-border);
      height: 1px;
      max-width: 80%;
      margin: auto;
    }
  }
</style>
<template>
  <div class="tiles content-container" v-if="!loading">
    <div 
      :style="[{'background-image': `url(${item &&item.image})`}]"
      v-for="(item) in tiles" :key="item.title" 
    >
      <span v-html="item.title" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
  },
  data() {
    return {
      tiles: null,
      loading: false,
    }
  },
  mounted() {
    if (!this.images) this.loadImages()
  },
  methods: {
    async loadImages() {
      this.loading = true
      const tiles = []
      await Promise.all(this.value.map(async (item) => {
        const image = !item.image ? null : await import(`@/assets${item.image}`)
        const tile = Object.assign({}, item, { image: image.default })
        tiles.push(tile)
      }))
      this.loading = false
      this.tiles = tiles
    }
  }
}
</script>
<style lang="scss">
  .tiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    > div {
      text-align: center;
      //padding: 20px;
      display: block;
      height: 200px;
      display: flex;
      font-weight: 600;
      align-items: center;
      color: #FFF;
      justify-content: center;
      background-size: cover;
      background-position: center center;
      position: relative;
      span {
        font-size: 1.4rem;
        padding: 5px 25px;
        width: 100%;
        display: block;
        z-index: 2;
      }
      &:before {
        content: '';
        background: rgba(#0F2644, .6);
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        z-index: 1;
      }
    }

    @media(max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media(max-width: 600px) {
      grid-template-columns: 1fr;
    }

  }
</style>
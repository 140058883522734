import { createRouter, createWebHistory } from 'vue-router'
import DynamicPage from '../views/DynamicPage.vue'
import Routes from '@/content/global/Routes.json'

const routes = Routes.map(page => {
  let data = null
  try {
    data = require(`@/content/pages/${page.name}.json`)
  } catch (e) {
    try {
      data = require(`@/content/pages/${page.name}.js`).default
    } catch (e) {
      data = null
    }
  }

  console.log(data)

  return {
    path: page.path,
    name: page.name,
    component: DynamicPage,
    meta: {
      data
    },
    beforeEnter(to, from, next) {
      if (page.redirect) {
       window.open(page.redirect, '_blank')
      }
      next()
    }
  }
})

routes.push(...[
  { 
    path: '/redirect',
    name: 'Redirect',
    component: DynamicPage,
    beforeEnter(to, from, next) {
      window.open(to.query.url, '_blank')
      next(false)
    }
  }
])

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router

<template>
  <div class="html-content content-container">
    <div class="banner__container" >
      <Logo data-aos="fade-up" data-aos-duration="800" v-if="value.logo" condense light />
      <div class="banner__content">
        <div class="banner__html" data-aos="fade-in" data-aos-delay="500" data-aos-duration="800" v-if="value.html" v-html="value.html" />
        <div class="banner__message" data-aos="fade-in" data-aos-delay="200" data-aos-duration="800" v-if="value.message" v-html="value.message" />
        <div class="banner__button" data-aos="fade-in" data-aos-duration="800" v-if="value.button">
          <Btn rounded large :route="value.route">{{ value.button }}</Btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      image: null
    }
  },
  async mounted() {
    if (!this.value.image) return
    const image = await import(`@/assets${this.value.image}`)
    this.image = image.default
  }
}
</script>

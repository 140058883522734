export default {
  meta: {
    title: "Acádio | Contact Us",
    keywords: "Acádio, learning management system, lms, online training",
    description: ""
  },
  sections: [
   {
    id: "pricing-wip",
    component: "Text",
	  heading: "Contact Us",
	  style: "",
      value: "Need assistance? Send us a message and our support team will be in touch shortly!"
	  },
	  {
      id: "form",
      component: "Form",
	    heading: "",
		  style: "margin-bottom: 60px; margin-top: -80px; max-width: 800px",
      value: {
        formId: "13cec24c-b8ad-47c2-8771-b5447b11af6e",
        image: ""
      }
    },
    {
      id: "badges",
      import: "Badges"
    }
  ]
}
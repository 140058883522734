<template>
  <div class="app">
    <Header />
    <div class="app__body">
      <router-view />
    </div>
    <Footer />
  </div>
</template>
<script>
import AOS from 'aos'
import 'aos/dist/aos.css'
import Header from '@/components/app/Header.vue'
import Footer from '@/components/app/Footer.vue'
import Theme from '@/mixins/Theme.js'
export default {
  components: {
    Header, Footer,
  },
  mixins: [ Theme ],
  mounted() {
    AOS.init()
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/index.scss';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &__body {
    flex: 1;
  }
}

</style>

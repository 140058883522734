<template>
  <div class="compare content-container">
    <div class="compare__item" v-for="item in value" :key="item.id">
      <Icon v-if="item.icon">{{ item.icon }}</Icon>
      <h3 v-html="item.title" />
      <p v-if="item.description" v-html="item.description" />
      <div class="compare__item__price" v-if="item.price">
        $<span v-html="item.price" />
      </div>
      <ul>
        <li v-for="feature in item.features" :key="feature">
          <div><Icon>mdi:check-circle-outline</Icon></div> <span v-html="feature" />
        </li>
      </ul>
      <div class="compare__item__button" v-if="item.button">
        <Btn rounded outline v-html="item.button" :route="item.route" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
  }
}
</script>
<style lang="scss">
  .compare {
    display: flex;
    justify-content: center;
    &__item {
      border: 3px solid var(--color-background);
      padding: 40px;
      border-radius: 5px;
      margin: 0 20px;
      text-align: center;
      flex: 1;
      > .icon {
        font-size: 80px;
        color: var(--color-accent);
        margin-bottom: 20px;
      }
      &__price {
        font-size: 2.5rem;
        font-weight: 600;
        background: var(--color-background);
        color: rgba(var(--color-primary-rgb), .4);
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-weight: 900;
          font-size: 3.5rem;
        }
      }
      h3 {
        font-size: 1.5rem;
        text-transform: none;
        margin-bottom: 15px;
      }
      p {
        opacity: .7;
      }
      ul {
        text-align: left;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          padding: 10px 0;
          font-size: 1rem;
          border-bottom: 2px solid var(--color-background);
          display: flex;
          //align-items: center;
          > div {
            margin-right: 15px;
          }
          .icon {
            top: 2px;
            font-size: 20px;
            color: var(--color-accent);
            
          }
          > span > p {
            font-size: .85rem;
            margin: 5px 0 0 0;
            padding: 0;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
      &__button {
      margin-top: 20px;
      button {
        width: 100%;
      }
      }
    }
  }

  @media(max-width: 1024px) {
    .compare {
      flex-wrap: wrap;
      &__item {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
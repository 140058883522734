<template>
  <component :is="tag" v-bind="$props" class="ux-button" @click="route ? gotoRoute() : href ? gotoHref() : $emit('click')" :class="classes">
    <transition name="label" mode="out-in">
      <span class="ux-button__loading" key="loading" v-if="loading">
        <div></div>
        <Icon v-if="icon">{{ icon }}</Icon>
        <span><slot></slot></span>
      </span>
      <span class="ux-button__slot" key="slot" v-else>
        <Icon v-if="icon">{{ icon }}</Icon>
        <span><slot></slot></span>
      </span>
    </transition>
  </component>
</template>
<script>
export default {
  props: {
    primary: Boolean,
    secondary: Boolean,
    loading: Boolean,
    shake: Boolean,
    label: String,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    danger: Boolean,
    ghost: Boolean,
    text: Boolean,
    disable: Boolean,
    rounded: Boolean,
    outline: Boolean,
    icon: String,
    highlight: Boolean,
    route: [String, Object],
    href: String,
    for: String,
    tag: {
      type: String,
      default: 'button'
    },
  },
  computed: {
    classes() {
      return [
        // style
        { 'ux-button--primary': this.primary || !this.secondary },
        { 'ux-button--secondary': this.secondary },
        { 'ux-button--outline': this.outline },
        { 'ux-button--danger': this.danger },
        { 'ux-button--ghost': this.ghost },
        { 'ux-button--text': this.text },
        { 'ux-button--highlight': this.highlight },

        // size
        { 'ux-button--small': this.small },
        { 'ux-button--medium': this.medium },
        { 'ux-button--large': this.large },

        //features
        { 'ux-button--disable': this.disable },
        { 'ux-button--rounded': this.rounded },
        { 'ux-button--loading': this.loading },
        { 'ux-button--shake': this.shake },
      ]
    }
  },
  methods: {
    gotoRoute() {
      this.$router.push(this.route)
    },
    gotoHref() {
      document.location.href = this.href
    }
  }
}
</script>
<style lang="scss">
.ux-button {
  display: inline-block;
  cursor: pointer;
  border: 3px;
  border: 1px solid transparent;
  border-radius: var(--settings-border-radius);
  padding: 7px 20px;
  line-height: 1.7rem;
  font-size: .9rem;
  text-transform: uppercase;
  white-space: nowrap;
  outline: none !important;
  box-sizing: border-box;
  font-weight: 900;
  transition: all .25s ease;
  i, svg, .ux-icon {
    margin: -4px 7px -4px 0;
    font-size: 22px;
  }
  &__slot {
    display: flex;
    align-items: center;
  }
  em {
    background: var(--color-body);
    border-radius: 50%;
    font-style: normal;
    font-size: 12px;
    font-weight: 700;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-top: -10px;
    color: var(--color-accent);
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--danger {
    $color-danger: #b74545;
    background: $color-danger !important;
    color: #FFF !important;
    &:hover {
      background: darken($color-danger, 10%) !important;
      color: #FFF !important;
    }
  }

  &--ghost, &--text {
    background: transparent !important;
    color: var(--color-accent) !important;
    text-decoration: underline;
    &:hover {
      background: transparent !important;
      color: var(--color-highlight) !important;
    }
    &.ux-button--disable {
      background: transparent !important;
      border-color: transparent !important;
      opacity: .4;
    }
  }

  &--primary {
    color: var(--color-body);
    background: var(--color-accent);
    border-color: var(--color-accent);
    &:hover {
      background: var(--color-highlight);
      border-color: var(--color-highlight);
      color: var(--color-body);
    }
  }

  &--secondary {
    color: var(--color-accent);
    font-weight: 600;
    background: var(--color-background);
    text-transform: none;
    border-radius: var(--settings-border-radius);
    padding: 5px 15px;
    &:hover {
      color: var(--color-body);
      background: var(--color-accent);
    }
    .ux-icon {
      font-size: 18px;
      margin-right: 0px
    }
  }

  &--small {
    line-height: normal;
    padding: 7px 20px !important;
    i, svg, .ux-icon {
      font-size: 18px;
    }
  }

  &--large {
    padding: 9px 32px !important;
    font-size: 1rem;
  }

  &--disable {
    pointer-events: none !important;
    background: rgba(#000, .05) !important;
    border-color: rgba(#000, .06) !important;
    color: #999 !important;
  }

  &--rounded {
    border-radius: 30px !important;
  }

  &--highlight {
    background-color: var(--color-highlight);
    border-color: var(--color-highlight);
    &:hover {
      border-color: var(--color-accent);
      background-color: var(--color-accent);
    }
  }

  &--outline {
    background: transparent;
    color: var(--color-accent);
    border: 1px solid var(--color-accent);
    &:hover {
      border-color: var(--color-highlight);
      color: var(--color-highlight);
      background: transparent;
    }
  }





  &__loading {
    > div {
      width: 27px;
      height: 27px;
      position: absolute;
      display: inline-block;
      margin-left: -13.5px;
      border: 3px solid rgba(#FFF, .4);
      border-radius: 50%;
      border-right: 3px solid rgba(#FFF, .8);
      animation: loader-spin 1s linear infinite;
    }
    > .ux-icon, > span {
      opacity: 0;
    }
  }

  @keyframes loader-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .label-enter-active, .label-leave-active {
    transition: all .25s ease;
  }
  .label-enter, .label-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }

  &--shake {
    animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

}

</style>
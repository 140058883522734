<template>
  <div class="contact content-container">
    <div>
      <div class="-fw600 -fs110 -mr80">
        <div class="-flex-center -mb10 -max-w300">
          <Icon class="-mr10 -fs24">location</Icon>
          <span v-html="contact.address" />
        </div>
        <div class="-flex-center -mb10">
          <Icon class="-mr10 -fs24">bxs:phone</Icon>
          <span v-html="contact.phone" />
        </div>
        <div class="-flex-center">
          <Icon class="-mr10 -fs24">email</Icon>
          <a :href="`mailto:${contact.email}`" v-html="contact.email" />
        </div>
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.136296549714!2d-81.35802828408367!3d28.595687692496664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e77011fcc4eed5%3A0x2203d8cbc1867d6f!2s444%20W%20New%20England%20Ave%2C%20Winter%20Park%2C%20FL%2032789!5e0!3m2!1sen!2sus!4v1658427654881!5m2!1sen!2sus" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
 </div>
</template>
<script>
import ContactData from  '@/content/global/Contact.json'
export default {
  data() {
    return {
      contact: ContactData,
    }
  }
}
</script>
<style lang="scss">
.contact {
  text-align: center;
  > div {
    max-width: 1120px;
    text-align: left;
    margin: auto;
    display: flex;
  }
  iframe {
    width: 100%;
    max-width: 600px;
    height: 300px;
  }
  @media(max-width: 1024px) {
    > div {
      display: block;
      > div {
        margin: 0 0 40px 0 !important;
      }
    }
  }
}
</style>
<template>
  <Splash class="statistic" :image="image" :opacity="value && value.opacity || 1">
    <div class="statistic__container content-container">
      <div class="statistic__value" v-html="value.stat" v-if="value.stat" />
      <div class="statistic__content">
        <div class="statistic__message" v-if="value.message" v-html="value.message" />
        <div class="statistic__source" v-if="value.source">
          <router-link v-if="value.route" :to="value.route" v-html="value.source" />
          <span v-else v-html="value.source" />
        </div>
      </div>
    </div>
  </Splash>
</template>
<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      image: null
    }
  },
  async mounted() {
    if (!this.value.image) return
    const image = await import(`@/assets${this.value.image}`)
    this.image = image.default
  }
}
</script>
<style lang="scss">
  .statistic {
    min-height: 400px;
    text-align: center;
    &__value {
      color: var(--color-accent);
      font-size: 8.5rem;
      font-weight: 900;
      margin-right: 60px;
    }
    &__message {
      font-size: 1.9rem;
      text-align: left;
    }
    &__source {
      margin-top: 30px;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        width: 10px;
        height: 1px;
        display: inline-block;
        background: #FFF;
        margin-right: 5px;
      }
    }
    &__container {
      color: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        flex: 1;
      }
      a {
        color: inherit;
        border-bottom: 1px solid transparent;
        transition: all .2s ease;
        opacity: .8;
        &:hover {
          color: inherit;
          opacity: 1;
          border-bottom: 1px solid #FFF;
        }
      }
    }
  }
</style>
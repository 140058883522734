<template>
  <div class="form" :key="Date.now()">
    <div>
      <div class="form__form">
        <div id="hs-form" />
      </div>
      <div
        class="form__image"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-easing="cubic"
        v-if="value && value.image"
      >
        <Image :value="value && value.image" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
  },
  mounted() {
    if (!window.hbspt) {
      this.init()
    } else {
      this.create()
    }
  },
  methods: {
    create() {
      if (!window.hbspt) return
      const payload = {
        region: this.value.region || 'na1',
        portalId: this.value.portalId || '21749332',
        formId: this.value.formId,
        target: '#hs-form',
      }
      window.hbspt.forms.create(payload)
    },
    init() {
      const url = `//js.hsforms.net/forms/embed/v2.js`
      const script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('async', true)
      script.setAttribute('src', url)
      script.onload = () => {
        this.create()
      }
      this.$el.appendChild(script)
    },
  },
};
</script>
<style lang="scss">
.form {
  max-width: 100%;
  text-align: center;
  padding: 0 40px;
  form {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
  }
  > div {
    margin: auto;
    display: flex;
    max-width: 1200px;
  }
  &__image {
    text-align: center;
    img {
      max-width: 500px;
    }
  }
  &__form {
    flex: 1;
    width: 100%;
    margin-right: 60px;
  }
  @media (max-width: 1024px) {
    .form__image {
      img {
        max-width: 100%;
      }
    }
    > div {
      display: block;
    }
  }
}
</style>